import React from "react"
import Address from "./element/Address"
import "./Footer.scss"

const Footer = () => {
  return (
    <footer>
      <div className="content">
        <div className="top">
          <div className="logo">
            {/* <img
                            src="https://ik.imagekit.io/tudb3viwg/footer/footer3.png?updatedAt=1681813581373"
                            alt=""
                        /> */}
            OMPHD
          </div>
          <div className="subtitle">
            A Promising Online-Based Healthcare System in Bangladesh
          </div>
          <ul className="links">
            <li>
              <a href="/">Home</a>
            </li>
            <span>|</span>
            <li>
              <a href="/about-us">About Us</a>
            </li>
            <span>|</span>
            <li>
              <a href="/event">Events</a>
            </li>
            <span>|</span>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <span>|</span>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
          </ul>
        </div>

        <div className="middle">
          <h3>FOLLOW US</h3>
          <ul className="links">
            <li>
              <a href="https://www.facebook.com/omphdofficial">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fa-brands fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
        </div>

        <div className="bottom">
          <Address
            cls="left"
            country="Address (Sylhet)"
            address="101 Holy View, Kanishail Road,
                        Shamimabad"
            number="01614312157-58"
            email="omphdofficial@gmail.com"
          />

          <Address
            cls="mid"
            country="Address (Rangpur)"
            address="Will be updated soon"
            number="01614312157-58"
            email="omphdofficial@gmail.com"
          />
        </div>
      </div>
      <div className="copyRight">
        <span>&#169; 2022 OMPHD, All Rights Reserved</span>
        <div className="right">
          <span>Designed & Developed by : </span>
          <a href="https://syncchrontech.com/">Sync Chron Tech Pvt. Ltd.</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
