import React from "react";
import Button from "./element/Button";
import map from "./img/map.png";
import meet from "./img/meet.png";
import "./DuelButton.scss";

const DuelButton = ({ link = "/contact#schedule" }) => {
    return (
        <section className="question">
            <div className="small__container">
                <h3>Want to get onboard at OMPHD Healthcare Program ?</h3>
                <div className="meeting">
                    <Button
                        link={link}
                        cls="btn__left"
                        p="Schedule a Meeting"
                        img={meet}
                    />
                    <Button
                        link={link}
                        cls="btn__right"
                        p="Make a Visit Request"
                        img={map}
                    />
                </div>
            </div>
        </section>
    );
};

export default DuelButton;
