import img1 from "./img/ibn-sina.png";
import img2 from "../../../../img/static/logo/user-avatar.png";
import img3 from "./img/sync-chron.png";

export const api = [
    {
        img: img1,
        p: (
            <>
                Ibn Sina Diagnostic
                <br />
                and Hospital
            </>
        ),
    },
    {
        img: img2,
        p: (
            <>
                Yaqul Ul Islam
                <br />
                Diagnostic Centre
            </>
        ),
    },
    {
        img: img3,
        p: (
            <>
                Sync Chron Tech
                <br />
                Pvt. Ltd.
            </>
        ),
    },
];
