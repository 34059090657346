import React from "react";
import "./SectionOne.scss";
import meet from "./img/meet.png";
import { HashLink } from "react-router-hash-link";

const SectionOne = () => {
    return (
        <section className="hero__section">
            <div className="hero__left">
                <h1>
                    <span>Bringing Revolution </span>
                    <br />
                    In Healthcare & Health Education
                    <br />
                    in Bangladesh
                </h1>
                <p>
                    <span>OMPHD</span> offers easy and affordable
                    <br />
                    healthcare services to ensure your
                    <br />
                    physical and mental well being.
                </p>

                <HashLink
                    className="hero__button button"
                    smooth
                    to="/contact#schedule"
                >
                    <span>Schedule a Meeting</span>
                    <img src={meet} alt="meet" />
                </HashLink>
            </div>
        </section>
    );
};

export default SectionOne;
