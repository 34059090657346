import img1 from "./img/client1.png";
import img2 from "./img/client2.png";
import img3 from "./img/client3.png";
import avatar from "../../../../img/static/logo/user-avatar.png";

export const api = [
    {
        small: "The health and the health consciousness of the students have significantly improved through regular checkups and feedback. We are glad to take OMPHD healthcare services. Will continue taking their services.",
        p1: "M. Sultana",
        p2: "Principal,",
        p3: "Tuhfatul Jannat Girls Madrasha",
        img: avatar,
    },
    {
        small: "They are always there whenever we need them.",
        p1: "Md. Koyes Ahmed",
        p2: "Principal,",
        p3: "Madrasha-e-Nurul Quran",
        img: avatar,
    },
    {
        small: "The Healthcare services they provide are very satisfactory. We highly recommend their services.",
        p1: "Md. Abdur Rahim",
        p2: "Principal,",
        p3: "Darul Arkam Hifj Madrasha",
        img: avatar,
    },
    {
        small: "The doctors are very cordial and helpful. We are very satisfied at their services.",
        p1: "Mawlana Atiqur Rahman",
        p2: "Principal,",
        p3: "Darus Sunnah Muradganj Ttile Madrasha",
        img: avatar,
    },
    {
        small: "The Healthcare Program is very satisfactory and the treatments are very good. We are pleased.",
        p1: "Mawlana Mahfuz Elahi",
        p2: "Principal,",
        p3: "Madrasha-e-Nurul Quran",
        img: avatar,
    },
];
