import React from "react";

const BoxV2 = ({ cls, h3, p, img }) => {
    return (
        <div className={`${cls} back__white`}>
            <div className="img__div">
                <img src={img} alt="box" />
            </div>
            <h3>{h3}</h3>
            <p>{p}</p>
        </div>
    );
};

export default BoxV2;
