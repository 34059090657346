import React from "react";
import CountUp from "react-countup";

const Counter = ({ h3, p, counterOn }) => {
    return (
        <div className="wrapper">
            <div className="dotted__circle">
                <div className="circle__content">
                    <h3>
                        {counterOn && (
                            <CountUp
                                start={0}
                                end={h3}
                                duration={3}
                                delay={0}
                            />
                        )}
                        +
                    </h3>
                    <p>{p}</p>
                </div>
            </div>
        </div>
    );
};

export default Counter;
