import React from "react";
import HeadLine from "../../common/HeadLine";
import { api } from "./api";
import Partner from "./element/Partner";
import "./SectionTen.scss";

const SectionTen = () => {
    return (
        <section>
            <div className="small__container">
                <HeadLine
                    h2={
                        <>
                            <span>OMPHD</span> Partners
                        </>
                    }
                    p="We are backed by some of the best in the business who
                    share our views regarding reachable and affordable
                    healthcare services."
                />
                <div className="partners__wrap">
                    {api.map((el, index) => (
                        <Partner key={index} img={el.img} p={el.p} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default SectionTen;
