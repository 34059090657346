import React, { useContext, useState } from "react";

const DataContext = React.createContext();

export const useAuth = () => {
    return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    return (
        <DataContext.Provider value={{ loading, setLoading }}>
            {children}
        </DataContext.Provider>
    );
};
