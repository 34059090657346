import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { api } from "../api";
import Item from "./Item";

const Carousel = () => {
    return (
        <div className="review__box owl__carousel">
            <OwlCarousel
                className="owl-theme"
                loop
                nav
                // autoplay
                autoplayTimeout={4000}
                autoplaySpeed={1500}
                autoplayHoverPause
                margin={10}
                responsive={{
                    0: {
                        items: 1,
                    },
                    576: {
                        items: 2,
                    },
                    991: {
                        items: 3,
                    },
                }}
            >
                {api.map((el, index) => (
                    <Item
                        key={index}
                        small={el.small}
                        p1={el.p1}
                        p2={el.p2}
                        p3={el.p3}
                        img={el.img}
                    />
                ))}
            </OwlCarousel>
        </div>
    );
};

export default Carousel;
