import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const Links = ({ activeBar }) => {
    const [active, setActive] = useState("");
    const handleDropdown = (e) => {
        active === "active" ? setActive("") : setActive("active");
    };
    return (
        <ul className={activeBar}>
            <li>
                <NavLink reloadDocument to="/" className="active">
                    home
                </NavLink>
            </li>
            <li>
                <NavLink reloadDocument to="/about-us">
                    about us
                </NavLink>
            </li>
            <li className={active}>
                <div onClick={handleDropdown} className="dropdown__link">
                    our programs
                    <FontAwesomeIcon icon={faAngleDown} />
                    <Dropdown />
                </div>
            </li>
            <li>
                <NavLink reloadDocument to="/event">
                    events
                </NavLink>
            </li>
            <li>
                <NavLink reloadDocument to="/contact">
                    contact us
                </NavLink>
            </li>
        </ul>
    );
};

export default Links;
