import React from "react";

const Box = ({ cls, h3, p, img }) => {
    return (
        <div className={`${cls} back__white`}>
            <h3>{h3}</h3>
            <p>{p}</p>
            <img src={img} alt="box" />
        </div>
    );
};

export default Box;
