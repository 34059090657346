import React from "react";
import img1 from "./img/map.png";
import img2 from "./img/meet.png";
import img3 from "./img/mobila.png";
import img4 from "./img/World health day-pana.png";
import "./SectionEleven.scss";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

const SectionEleven = () => {
    return (
        <section className="contact">
            <div className="small__container">
                <div className="contact__left">
                    <h2>
                        Be a Valued Client of <span>OMPHD</span>
                    </h2>
                    <p>
                        By subscribing to <span>OMPHD</span>, you can now easily
                        maintain everything related to your physical and mental
                        health. And you can do so at a affordable price.
                    </p>
                    <div className="meeting">
                        <HashLink
                            smooth
                            to="/contact#schedule"
                            className="button btn__left"
                        >
                            <p>Schedule a Meeting</p>
                            <span>
                                <img src={img2} alt="meeting" />
                            </span>
                        </HashLink>
                        <HashLink
                            smooth
                            to="/contact#schedule"
                            className="button btn__right"
                        >
                            <p>Make a Visit Request</p>
                            <span>
                                <img src={img1} alt="map" />
                            </span>
                        </HashLink>
                    </div>
                    <NavLink
                        to="/contact"
                        reloadDocument
                        className="button btn__contact"
                    >
                        <p>Contact OMPHD</p>
                        <span>
                            <img src={img3} alt="mobile" />
                        </span>
                    </NavLink>
                </div>
                <div className="contact__right">
                    <img src={img4} alt="world-health-day" />
                </div>
            </div>
        </section>
    );
};

export default SectionEleven;
