export const api = [
    {
        h3: (
            <>
                <span>School & College</span>
                <br />
                Health Program
            </>
        ),
        small: (
            <>Healthcare services for School & College students & teachers</>
        ),
        link: "/school-college-program",
    },
    {
        h3: (
            <>
                <span>University</span>
                <br />
                Health Program
            </>
        ),
        small: (
            <>Healthcare services for School & College students & teachers</>
        ),
        link: "/university-program",
    },
    {
        h3: (
            <>
                <span>Madrasha</span>
                <br />
                Health Program
            </>
        ),
        small: (
            <>Healthcare services for School & College students & teachers</>
        ),
        link: "/madrasha-program",
    },
    {
        h3: (
            <>
                <span>Corporate</span>
                <br />
                Health Program
            </>
        ),
        small: (
            <>Healthcare services for School & College students & teachers</>
        ),
        link: "/corporate-program",
    },
];
