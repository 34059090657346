import React from "react";

const Partner = ({ img, p }) => {
    return (
        <div className="partners">
            <img src={img} alt="partner" />
            <p>{p}</p>
        </div>
    );
};

export default Partner;
