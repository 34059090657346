import img1 from "./img/slide-2.jpeg";
import img2 from "./img/slide1.jpeg";
import img3 from "./img/slide-3.jpeg";
import img4 from "./img/slide-4.jpeg";

export const api = [
    {
        img: img1,
        p1: (
            <>
                <span>OMPHD</span> Doctors conducting a Seminar with the
            </>
        ),
        p2: <span>Principals of various madrashas of Rangpur.</span>,
    },
    {
        img: img2,
        p1: (
            <>
                <span>OMPHD</span> Doctor performing regular health check-up of
                a Madrasha Student during visiting day of
            </>
        ),
        p2: <span>Madrasha Health Program</span>,
    },
    {
        img: img3,
        p1: (
            <>
                <span>OMPHD</span> Official conducting a Seminar on the
            </>
        ),
        p2: <span>Madrasha Health Program at Rangpur.</span>,
    },
    {
        img: img4,
        p1: (
            <>
                <span>OMPHD</span> Doctor performing regular health check-up of
                a Madrasha Student during visiting day of
            </>
        ),
        p2: <span>Madrasha Health Program</span>,
    },
];
