import React from "react";
import { HashLink } from "react-router-hash-link";

const Button = ({ cls, p, img, link }) => {
    return (
        <HashLink className={`button ${cls} back__white`} smooth to={link}>
            <p>{p}</p>
            <span>
                <img src={img} alt="button" />
            </span>
        </HashLink>
    );
};

export default Button;
