import React from "react";
import SectionOne from "../../components/static/home/sectionOne/SectionOne";
import SectionTwo from "../../components/static/home/sectionTwo/SectionTwo";
import SectionThree from "../../components/static/home/sectionThree/SectionThree";
import SectionFour from "../../components/static/home/sectionFour/SectionFour";
import SectionFive from "../../components/static/home/sectionFive/SectionFive";
import SectionSix from "../../components/static/home/sectionSix/SectionSix";
import SectionSeven from "../../components/static/home/sectionSeven/SectionSeven";
import SectionEight from "../../components/static/home/sectionEight/SectionEight";
import SectionNine from "../../components/static/home/sectionNine/SectionNine";
import SectionTen from "../../components/static/home/sectionTen/SectionTen";
import SectionEleven from "../../components/static/home/sectionEleven/SectionEleven";

const Home = () => {
    return (
        <div className="home__section">
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            <SectionFive />
            <SectionSix />
            <SectionSeven />
            <SectionEight />
            {/* <SectionNine /> */}
            <SectionTen />
            <SectionEleven />
        </div>
    );
};

export default Home;
