import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../../../pages/static/Home";
import "./Navbar.scss";
import Links from "./elements/Links";
import bar from "./img/menu.png";
import close from "./img/close.png";
import logo from "../../../img/static/logo/logo.png";

const Navbar = () => {
    const [activeBar, setActiveBar] = useState("");

    const handleActiveBar = (e) => {
        activeBar === "active" ? setActiveBar("") : setActiveBar("active");
    };

    return (
        <header>
            <nav>
                <NavLink reloadDocument to="/" className="logo">
                    <div className="img__div">
                        <img src={logo} alt="Logo" />
                    </div>
                    <span>OMPHD</span>
                </NavLink>

                <Links activeBar={activeBar} />

                <div className="right" >
                <a  href="https://drive.google.com/uc?export=download&id=1tHoMB6ic-ZVQdtJ9-HooK_EnhxnhWAeH"
              download={"cdsc.apk"} className="button" style={{ color: "#9370db",borderRadius:"5px" }} >
            Download APP
        </a>
            
                   
                    {/* <NavLink to="" reloadDocument className="button">
                        Sign Up
                    </NavLink> */}

                    <div onClick={handleActiveBar} className="checkbtn">
                        <img
                            src={activeBar === "active" ? close : bar}
                            alt="bar"
                        />
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
