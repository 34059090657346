import React from "react";
import HeadLine from "../../common/HeadLine";
import Corousel from "./element/Corousel";
import "./SectionSix.scss";

const SectionSix = () => {
    return (
        <section className="activities">
            <div className="small__container">
                <HeadLine
                    h2={
                        <>
                            <span>OMPHD</span> activities
                        </>
                    }
                    p=""
                />
                <Corousel />
            </div>
        </section>
    );
};

export default SectionSix;
