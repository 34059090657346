import React from "react";
import HeadLine from "../../common/HeadLine";
import img1 from "./img/sky.png";
import img2 from "./img/purple.png";
import Box from "./element/Box";
import { api } from "./index";
import "./SectionThree.scss";

const SectionThree = () => {
    return (
        <section className="programs">
            <div className="small__container">
                <HeadLine
                    h2={
                        <>
                            <span>OMPHD</span> Healthcare Programs
                        </>
                    }
                    p={
                        <>
                            OMPHD aims to create a practical online-based
                            healthcare system in Bangladesh <br />& designed the
                            following programs to provide its healthcare
                            services and maximize its mission and vision
                            implementation.
                        </>
                    }
                />
                <div className="programs__back">
                    <img src={img1} alt="sky" />
                    <img src={img2} alt="purple" />
                </div>

                <div className="program__container">
                    {api.map((el, index) => (
                        <Box
                            key={index}
                            h3={el.h3}
                            small={el.small}
                            link={el.link}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default SectionThree;
