import React from "react";
import Navbar from "./static/navbar/Navbar";
import Footer from "./static/footer/Footer";

const Layout = ({ children }) => {
    return (
        <div className="main__container">
            <Navbar />
            <div className="child__container">{children}</div>
            <Footer />
        </div>
    );
};

export default Layout;
