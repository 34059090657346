import React from "react";
import HeadLine from "../../common/HeadLine";
import { api } from "./index";
import Box from "./element/Box";
import RightBox from "./element/RightBox";
import "./SectionTwo.scss";

const SectionTwo = () => {
    return (
        <section className="services small__container">
            <HeadLine
                h2={
                    <>
                        Why Take <span>OMPHD</span>’s Healthcare Services?
                    </>
                }
                p="OMPHD provides all the essential healthcare services
                prioritizing quality."
            />
            <div className="service__content">
                <Box
                    cls="left__box"
                    h3={api.leftBox.h3}
                    p={api.leftBox.p}
                    img={api.leftBox.img}
                />

                <RightBox array={api.rightBox} />
            </div>
        </section>
    );
};

export default SectionTwo;
