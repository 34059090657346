export const api = [
    {
        h3: 7000,
        p: "Subscribers",
    },
    {
        h3: 20,
        p: "Institute",
    },
    {
        h3: 30,
        p: "Doctors",
    },
    {
        h3: 5,
        p: "Regions",
    },
];
