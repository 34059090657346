import React from "react";
import circle from "../img/slide-right.png";

const Item = ({ img, p1, p2 }) => {
    return (
        <div className="item slider">
            <div className="slider__left">
                <img src={img} alt="" />
            </div>

            <div className="slider__right">
                {/* <img src={circle} alt="Circle" /> */}
                <p>{p1}</p>
                <p>{p2}</p>
                <div className="bar"></div>
            </div>
        </div>
    );
};

export default Item;
