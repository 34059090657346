import React from "react";
import img1 from "./img/ceo.png";
import img2 from "./img/mints.png";
import img3 from "./img/quote.png";
import img4 from "./img/right-quote.png";
import "./SectionFour.scss";

const SectionFour = () => {
    return (
        <section>
            <div className="ceo__quote small__container">
                <div className="ceo">
                    <div className="profile__img">
                        <img src={img1} alt="ceo" />
                    </div>
                    <div className="circle">
                        <img src={img2} alt="circle" />
                    </div>
                </div>
                <div className="quote">
                    <span>
                        <img src={img3} alt="left-quote" />
                    </span>
                    <p>
                        Welcome to Omphd, your trusted healthcare partner in
                        Bangladesh. As CEO, I'm proud to lead a team that
                        provides top-notch healthcare services for all. Our
                        commitment extends to every individual, ensuring a
                        healthier and happier community. Join us on the journey
                        to well-being!
                    </p>
                    <span className="right__quote">
                        <img src={img4} alt="right-quote" />
                    </span>

                    <h5>Md. Rokibul Hasan</h5>
                    <small>CEO, OMPHD</small>
                </div>
            </div>
        </section>
    );
};

export default SectionFour;
