import React from "react";

const HeadLine = ({ h2, p }) => {
    return (
        <div className="main__head text__center">
            <h2>{h2}</h2>
            <p>{p}</p>
        </div>
    );
};

export default HeadLine;
