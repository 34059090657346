import React from "react";
import DropdownLink from "./DropdownLink";

const Dropdown = () => {
    return (
        <div className="dropdown">
            <div className="headline">Our Programs</div>
            <DropdownLink
                link="/school-college-program"
                headerText="School & College Health Program"
                titleText="Explore more about the program services"
            />
            <DropdownLink
                link="/university-program"
                headerText="University Health Program"
                titleText="Explore more about the program services"
            />
            <DropdownLink
                link="/madrasha-program"
                headerText="Madrasha Health Program"
                titleText="Explore more about the program services"
            />
            <DropdownLink
                link="/corporate-program"
                headerText="Corporate Health Program"
                titleText="Explore more about the program services"
            />
            <DropdownLink
                link="/tele-medicine"
                headerText="Tele Medicine Service"
                titleText="Explore more about the program services"
            />
        </div>
    );
};

export default Dropdown;
