import React from "react";

const Item = ({ small, p1, p2, p3, img }) => {
    return (
        <div className="clients__review">
            <small>{small}</small>
            <div className="clients__info">
                <img src={img} alt="" />
                <div className="client__details">
                    <p>{p1}</p>
                    <p>{p2}</p>
                    <p>{p3}</p>
                </div>
            </div>
        </div>
    );
};

export default Item;
