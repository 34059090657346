import React from "react";
import { NavLink } from "react-router-dom";

const Box = ({ h3, small, link }) => {
    return (
        <div className="box back__white">
            <h3>{h3}</h3>
            <p>{small}</p>
            <div className="overlay overlayLeft">
                <NavLink reloadDocument to={link}>
                    Learn More
                </NavLink>
            </div>
        </div>
    );
};

export default Box;
