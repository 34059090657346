import React, { Suspense, lazy } from "react"
import Layout from "./components/Layout"
import { Route, Routes } from "react-router-dom"
import Preloader from "./Preloader"
import "./App.scss"

const LazyHome = lazy(() => import("./pages/static/Home"))
const LazyEvent = lazy(() => import("./pages/static/Event"))
const LazySchoolCollegeProgram = lazy(() =>
  import("./pages/static/SchoolCollegeProgram")
)
const LazyMadrashaProgram = lazy(() => import("./pages/static/MadrashaProgram"))

const LazyUniversityProgram = lazy(() =>
  import("./pages/static/UniversityProgram")
)

const LazyCorporateProgram = lazy(() =>
  import("./pages/static/CorporateProgram")
)

const LazyContactProgram = lazy(() => import("./pages/static/Contact"))

const LazyAbout = lazy(() => import("./pages/static/About"))
const LazyPrivacyPolicy = lazy(() => import("./pages/static/PrivacyPolicy"));
const TaleMedicine = lazy(() => import("./pages/static/TeleMedicine"));

const App = () => {
  return (
    <Layout>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/" element={<LazyHome />} />
          <Route path="/event" element={<LazyEvent />} />
          <Route
            path="/school-college-program"
            element={<LazySchoolCollegeProgram />}
          />
          <Route path="/madrasha-program" element={<LazyMadrashaProgram />} />
          <Route
            path="/university-program"
            element={<LazyUniversityProgram />}
          />
          <Route path="/corporate-program" element={<LazyCorporateProgram />} />
          <Route path="/contact" element={<LazyContactProgram />} />
          <Route path="/about-us" element={<LazyAbout />} />
          <Route path="/privacy-policy" element={<LazyPrivacyPolicy />} />
          <Route path="/tele-medicine" element={<TaleMedicine />} />
        </Routes>
      </Suspense>
    </Layout>
  )
}

export default App
