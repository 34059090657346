import React from "react";
import { NavLink } from "react-router-dom";

const DropdownLink = ({ link, headerText, titleText }) => {
    return (
        <NavLink reloadDocument to={link}>
            <span>{headerText}</span>
            <span>{titleText}</span>
        </NavLink>
    );
};

export default DropdownLink;
