import React from "react";
import HeadLine from "../../common/HeadLine";
import img1 from "./img/slide-right.png";
import img2 from "./img/mints.png";
import Carousel from "./element/Carousel";
import "./SectionEight.scss";

const SectionEight = () => {
    return (
        <section className="review">
            <div className="small__container">
                <HeadLine
                    h2={
                        <>
                            Client’s experience of <span>OMPHD</span> services
                        </>
                    }
                    p="Our growth is directly connected to our Client’s
                    satisfaction of our services."
                />
                <div className="review__back">
                    <img src={img1} alt="Circle" />
                    <img src={img2} alt="Circle" />
                </div>

                <Carousel />
            </div>
        </section>
    );
};

export default SectionEight;
