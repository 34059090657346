import React, { useState } from "react";
import HeadLine from "../../common/HeadLine";
import Counter from "./element/Counter";
import { api } from "./api";
import ScrollTrigger from "react-scroll-trigger";
import "./SectionSeven.scss";

const SectionSeven = () => {
    const [counterOn, setCounterOn] = useState(false);
    return (
        <section>
            <ScrollTrigger onEnter={() => setCounterOn(true)}>
                <div className="small__container">
                    <HeadLine
                        h2={
                            <>
                                <span>OMPHD</span> at a Glance
                            </>
                        }
                    />
                    <div className="glance back__white">
                        {api.map((el, index) => (
                            <Counter
                                key={index}
                                h3={el.h3}
                                p={el.p}
                                counterOn={counterOn}
                            />
                        ))}
                    </div>
                </div>
            </ScrollTrigger>
        </section>
    );
};

export default SectionSeven;
