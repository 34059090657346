import {
    faEnvelope,
    faLocationDot,
    faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Address = ({ cls, country, address, number, email }) => {
    return (
        <div className={cls}>
            <div className="address__title">{country}</div>

            <div className="address__location">
                <FontAwesomeIcon icon={faLocationDot} />
                <span>{address}</span>
            </div>

            <div className="address__location">
                <FontAwesomeIcon icon={faPhone} />
                <span>{number}</span>
            </div>

            <div className="address__location">
                <FontAwesomeIcon icon={faEnvelope} />
                <span>{email}</span>
            </div>
        </div>
    );
};

export default Address;
