import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { api } from "../index";
import Item from "./Item";

const Corousel = () => {
    return (
        <div className="activity owl__carousel">
            <OwlCarousel
                className="owl-theme"
                loop
                nav
                autoplay
                autoplayTimeout={3000}
                autoplayHoverPause
                margin={10}
                items={1}
            >
                {api.map((el, index) => (
                    <Item key={index} img={el.img} p1={el.p1} p2={el.p2} />
                ))}
            </OwlCarousel>
        </div>
    );
};

export default Corousel;
