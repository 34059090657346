import React from "react";
import BoxV2 from "./BoxV2";

const RightBox = ({ array }) => {
    return (
        <div className="right__boxes">
            {array.map((el, index) => (
                <BoxV2
                    key={index}
                    cls="small__box"
                    img={el.img}
                    h3={el.h3}
                    p={el.p}
                />
            ))}
        </div>
    );
};

export default RightBox;
