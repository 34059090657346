import img1 from "./img/service.png";
import img2 from "./img/online-registration.png";
import img3 from "./img/mobila.png";
import img4 from "./img/hand.png";
import img5 from "./img/best-price.png";

export const api = {
    leftBox: {
        img: img1,
        h3: "Regular Health Check Up",
        p: (
            <>
                OMPHD provides essential routine health check up service. Also
                conducts regular follow-ups based on health progress.
            </>
        ),
    },

    rightBox: [
        {
            img: img2,
            h3: "Online Health Profile",
            p: <>View health profile & history at any time from any place</>,
        },
        {
            img: img3,
            h3: "Tele-Medicine",
            p: <>Live medical session with Doctors in emergency</>,
        },
        {
            img: img4,
            h3: "Counselling & Seminars",
            p: <>Mental Healthcare counselling & Health education seminars</>,
        },
        {
            img: img5,
            h3: "Most Affordable",
            p: <>All the Healthcare services at the most affordable price</>,
        },
    ],
};
